import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { notification } from "antd";
import { RemoveError } from "../../redux/actions/errorActions";

const ErrorNotificator = () => {
    const dispatch = useDispatch();
    const [api, contextHolder] = notification.useNotification();

    const errors = useSelector(state => state.errorsStorage.errors)

    useEffect(() => {
        showErrors();
    });

    function showErrors(){
        if(!errors || !errors.length){
            return;
        }

        errors.forEach(error => {
            api.error({
                message: error.title,
                description: error.description,
                placement: 'bottomRight',
            })
            dispatch(RemoveError(error))
        });
    }

    return <>
        { contextHolder }
    </>
}

export default ErrorNotificator;