import { ServiceCategoryTypes } from "../actionTypes";

const initialState = {
    categories: []
}

const ServiceCategoryReducer = (state = initialState, action) => {
    switch(action.type)
    {
        case ServiceCategoryTypes.SET_CATEGORIES:
            return {
                ...state,
                categories: action.payload
            }
        default: 
            return state;
    }
}

export default ServiceCategoryReducer;