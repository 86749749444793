import Modal from './modal';

const ConfirmPopup = (props) => {
    return (
        <Modal
            isOpen={props.isOpen}
            title={props.title}
            onCancel={props.onCancel}
            onConfirm={props.onConfirm}
            isLoading={props.isLoading}
        >
            <div>{props.text}</div>
        </Modal>
    )   
}

export default ConfirmPopup;