import Http from '../../infrastructure/http';
import baseUrl from '../../infrastructure/url';
import { ServiceTypes } from "../actionTypes";
import { SetServicesAction } from "../actions/serviceAction"; 
import { MiddlewareErrorHandler } from "./middlewareExtensions";
import moment from "moment";

const serviceUrl = baseUrl + '/api/service';

const serviceCategoryMiddleware = (store) => (next) => async (action) => {
    const state = store.getState();
    const http = new Http(state.account.accessToken.value);

    var errorHandler = new MiddlewareErrorHandler(store);

    switch(action.type)
    {
        case ServiceTypes.GET: 
            action.before();
            errorHandler.execute(GetServices, action.after);
            break;
        case ServiceTypes.SAVE:
            action.before();
            errorHandler.execute(SaveService, action.after);
            break;
        case ServiceTypes.DELETE:
            action.before();
            errorHandler.execute(DeleteService, action.after);
            break; 
    }
    
    return next(action);

    async function GetServices()
    {
        const response = await http.get(serviceUrl);

        const services = response.services;
        services.forEach((service, index) => {
            service.key = index;
            service.order = index + 1;
            service.duration = moment(service.duration, "hh:mm:ss");
            service.categoryId = service.typeId;
        })

        store.dispatch(SetServicesAction(services));
    }

    async function SaveService()
    {
        action.payload.typeId = action.payload.categoryId;
        await http.post(serviceUrl, action.payload);
    }

    async function DeleteService()
    {
        await http.delete(`${serviceUrl}?id=${action.payload}`);
    }
}

export default serviceCategoryMiddleware;