import { useEffect, useState } from "react";
import { Row, Typography, Button, Table } from "antd";
import { AiOutlinePlus } from "react-icons/ai";
import { useDispatch, useSelector } from "react-redux";
import { GetCategoriesAction } from "../redux/actions/serviceCategoryActions";
import { BiPencil, BiTrash } from "react-icons/bi";
import ServiceCategoryForm from "../components/serviceCategories/serviceCategoryForm";
import ServiceCategoryDeleteDialog from "../components/serviceCategories/serviceCategoryDeleteDialog";

const { Title } = Typography;

const ServiceCategories = () => {
    const dispatch = useDispatch();

    const [isFormOpen, setIsFormOpen] = useState();
    const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState();
    const [isCategoriesLoading, setIsCategoriesLoading] = useState();
    const [currentCategory, setCurrentCategory] = useState({});

    const categories = useSelector(state => state.serviceCategories.categories);

    const getCategories = () => {
        const before = () => setIsCategoriesLoading(true);
        const after = () => setIsCategoriesLoading(false);
        dispatch(GetCategoriesAction(before, after));
    }

    const openCreateForm = (category) => {
        if (currentCategory.id > 0)
        {
            setCurrentCategory({});
        }

        setIsFormOpen(true);
    }

    const closeSaveForm = (afterClose) => {
        setIsFormOpen(false);
        afterClose();
    } 

    const openEditForm = (category) => {
        setCurrentCategory(category);
        setIsFormOpen(true);
    }

    const handleFormDone = () => {
        setCurrentCategory({});
        setIsFormOpen(false);
        getCategories();
    }

    const openDeleteDialoag = (category) => {
        setCurrentCategory(category);
        setIsDeleteDialogOpen(true);
    }

    const closeDeleteDialog = (afterClose) => {
        setIsDeleteDialogOpen(false);
        afterClose();
    } 

    const handleDeleteDialogDone = () => {
        setIsDeleteDialogOpen(false);
        getCategories();
    }

    const columns = [
        {   
            title: '#',
            dataIndex: 'order',
            key: 'order'
        },
        {
            title: 'Название',
            dataIndex: 'name',
            key: 'name'
        },
        {   
            title: 'Oперации',
            dataIndex: 'id',
            key: 'operations',
            render: (id, category) => {
                return (<Row>
                    <Button icon={<BiPencil/>} type="text" onClick={() => openEditForm(category)}/>
                    <Button icon={<BiTrash/>} type="text" danger onClick={() => openDeleteDialoag(category)}/>
                </Row>);
            }
        }
    ];

    useEffect(() => {
        getCategories();
    }, []);

    return (<div>
        <ServiceCategoryForm 
            isOpen={isFormOpen} 
            category={currentCategory}
            onCancel={closeSaveForm}
            onDone={handleFormDone}/>

        <ServiceCategoryDeleteDialog
            isOpen={isDeleteDialogOpen}
            category={currentCategory}
            onCancel={closeDeleteDialog}
            onDone={handleDeleteDialogDone}/>

        <Row>
            <Title level={4}>Категории</Title>
            <Button 
                type="primary" 
                shape="circle" 
                onClick={openCreateForm}
                icon={<AiOutlinePlus/>} 
                style={{transform: 'scale(.8)', marginLeft: '10px'}}
            />
        </Row>

        <Table
            columns={columns}
            dataSource={categories}
            loading={isCategoriesLoading}
            pagination={false}
            style={{ width: '800px', marginTop: '20px' }}
            scroll={{ y: 300,  }}
        />
        
    </div>);
}

export default ServiceCategories;