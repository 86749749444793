import { useState } from "react";

import { Form, Input, message } from "antd";

import Modal from "../common/modal";
import Button from "./button";
import PhoneInput from "../common/phoneInput";
import OrderService from "../../services/orderService";


const OrderModalForm = (props) => {
    const [isLoading, setLoadingStatus] = useState();
    const [messageApi, contextHolder] = message.useMessage();
    
    const sendOrder = async (formData) => {
        const customerData = {
            name: formData.name,
            phone: formData.phone,
            email: formData.email ?? ''
        };

        var orderService = new OrderService();

        try{
            setLoadingStatus(true);
            await orderService.order(props.service.id, customerData);
            
            messageApi.open({
                type: 'success',
                content: 'Заявка успешно отправлена, ожидайте!'
            })        
        } catch(e){
            messageApi.open({
                type: 'error',
                content: 'Что-то пошло не так, попробуйте позже.'
            })
        } finally {
            setLoadingStatus(false);
            // HACK: setting <html> element style to enable scrolling after modal close.
            document.documentElement.style.overflowY = '';
            props.onCancel();
        }
    }

    return (
        <>
            {contextHolder}
            <Modal  
                title={`Заказать "${props.service?.name}"`}
                {...props}
                isLoading={isLoading}>
                {{
                    body: (
                        <Form
                            id="orderForm" 
                            layout="vertical"
                            onFinish={sendOrder}
                        >
                            <Form.Item 
                                label="Имя" 
                                name="name"
                                rules={[{required: true, message: 'Введите ваше имя'}]}>
                                <Input size="large" style={{borderRadius: '15px'}}/>
                            </Form.Item>

                            <Form.Item 
                                label="Телефон" 
                                name="phone"
                                rules={[{required: true, min: 17, message: 'Введите ваш номер телефона'}]}>
                                <PhoneInput size="large" style={{borderRadius: '15px'}}/>
                            </Form.Item>

                            <Form.Item 
                                label="Почта" 
                                name="email">
                                <Input size="large" style={{borderRadius: '15px'}}/>
                            </Form.Item>
                        </Form>
                    ),
                    footer: (
                        <div>
                            <Button 
                                loading={isLoading} 
                                type="danger" 
                                size="medium"
                                htmlType="submit"
                                form="orderForm">
                                Подтвердить
                            </Button>
                        </div>
                    )
                }}
            </Modal>
        </>
    );
}


export default OrderModalForm;