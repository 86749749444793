import { AccountTypes } from '../actionTypes'

const loginData = uploadLoginData();
const initialState = {
    accessToken: parseToken(loginData.accessToken),
    refreshToken: loginData.refreshToken
}

export default function accountReducer(state = initialState, action)
{
    switch(action.type)
    {
        case AccountTypes.LOGIN: 
            const accessToken = action.payload.accessToken;
            const refreshToken = action.payload.refreshToken;
            persistLoginData(accessToken, refreshToken)

            return {
                ...state,
                accessToken: parseToken(accessToken),
                refreshToken: refreshToken,
            };
        case AccountTypes.LOGOUT: 
            persistLoginData('', '');

            return {
                ...state,
                accessToken: '',
                refreshToken: ''
            };
        default: 
            return state;
    }
}

function parseToken(token)
{
    if (!token)
    { 
        return false;
    }

    const payloadSection = token.split('.')[1];
    const payloadString = atob(payloadSection);
    const payload = JSON.parse(payloadString);

    return {
        ...payload,
        value: token,
        exp: new Date(payload.exp * 1000)
    }
}

function persistLoginData(accessToken, refreshToken)
{
    localStorage.setItem('accessToken', accessToken);
    localStorage.setItem('refreshToken', refreshToken);
}

function uploadLoginData()
{
    return {
        accessToken: localStorage.getItem('accessToken'),
        refreshToken: localStorage.getItem('refreshToken')
    }
}