import baseUrl from "../infrastructure/url";
import Http from "../infrastructure/http";

class OrderService{
    constructor(){
        this.url = baseUrl + '/api/order';
        this.http = new Http();

        this.order = this.order.bind(this);
        this.orderHelp = this.orderHelp.bind(this);
    }

    async order(serviceId, customer){
        const data = {
            serviceId,
            customer
        };

        await this.http.post(this.url, data);
    }

    async orderHelp(customer){
        const data = { 
            customer
        };

        await this.http.post(this.url + '/help', data);
    }
}

export default OrderService;