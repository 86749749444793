import { useEffect, useState } from "react";
import { Row, Typography, Button, Table } from "antd";
import { AiOutlinePlus } from "react-icons/ai";
import { useDispatch, useSelector } from "react-redux";
import { BiPencil, BiTrash } from "react-icons/bi";
import { GetServicesAction } from "../redux/actions/serviceAction";
import ServiceForm from "../components/services/serviceForm";
import ServiceDeleteDialog from "../components/services/serviceDeleteDialog";

const { Title } = Typography;

const Services = () => {
    const dispatch = useDispatch();

    const [isFormOpen, setIsFormLoading] = useState();
    const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState();
    const [isServicesLoading, setIsServicesLoading] = useState();
    const [currentService, setCurrentService] = useState({});

    const services = useSelector(state => state.services.services);

    const getServices = () => {
        const before = () => setIsServicesLoading(true);
        const after = () => setIsServicesLoading(false);
        dispatch(GetServicesAction(before, after));
    }

    const openCreateForm = () => {
        if(currentService.id > 0){
            setCurrentService({});
        }

        setIsFormLoading(true);
    }

    const openEditForm = (service) => {
        setCurrentService(service);
        setIsFormLoading(true);
    }

    const handleFormDone = () => {
        setCurrentService({});
        setIsFormLoading(false);
        getServices();
    }

    const openDeleteDiloag = (service) => {
        setCurrentService(service);
        setIsDeleteDialogOpen(true);
    }

    const handleDeleteDialogDone = () => {
        setIsDeleteDialogOpen(false);
        getServices();
    }

    const columns = [
        {   
            title: '#',
            dataIndex: 'order',
            key: 'order'
        },
        {
            title: 'Название',
            dataIndex: 'name',
            key: 'name'
        },
        {   
            title: 'Oперации',
            dataIndex: 'id',
            key: 'operations',
            render: (id, service) => {
                return (<Row>
                    <Button icon={<BiPencil/>} type="text" onClick={() => openEditForm(service)}/>
                    <Button icon={<BiTrash/>} type="text" danger onClick={() => openDeleteDiloag(service)}/>
                </Row>);
            }
        }
    ];

    useEffect(() => {
        getServices();
    }, []);

    return (<div>
        <ServiceForm 
            isOpen={isFormOpen} 
            service={currentService}
            onCancel={() => setIsFormLoading(false)}
            onDone={handleFormDone}/>

        <ServiceDeleteDialog
            isOpen={isDeleteDialogOpen}
            service={currentService}
            onCancel={() => setIsDeleteDialogOpen(false)}
            onDone={handleDeleteDialogDone}/>

        <Row>
            <Title level={4}>Сервисы</Title>
            <Button 
                type="primary" 
                shape="circle" 
                onClick={openCreateForm}
                icon={<AiOutlinePlus/>} 
                style={{transform: 'scale(.8)', marginLeft: '10px'}}
            />
        </Row>

        <Table
            columns={columns}
            dataSource={services}
            loading={isServicesLoading}
            pagination={false}
            style={{ width: '800px', marginTop: '20px' }}
            scroll={{ y: 300 }}
        />
    </div>);
}

export default Services;