import { ServiceTypes } from "../actionTypes"

export const GetServicesAction = (before, after) => {
    return {
        type: ServiceTypes.GET,
        before,
        after
    };
}

export const SetServicesAction = (payload) => {
    return {
        type: ServiceTypes.SET_SERVICES,
        payload
    };
}

export const SaveServiceAction = (service, before, after) => {
    return {
        type: ServiceTypes.SAVE,
        payload: service,
        before,
        after
    };
}

export const DeleteServiceAction = (serviceId, before, after) => {
    return {
        type: ServiceTypes.DELETE,
        payload: serviceId,
        before, 
        after
    };
}
