import { Input } from "antd"
import { useState } from "react"

const PhoneInput = (props) => {
    
    const [value, setValue] = useState('');

    const getNumbersValue = (value) => {
        return value.replace(/\D/g, "");
    }

    const onPhoneInput = (e) => {
        const inputValue = e.target.value;
        const selectionStart = e.target.selectionStart
        let numbersValue = getNumbersValue(inputValue);
        let formattedValue = '';

        if(!numbersValue){
            return setValue('');
        }

        if(inputValue.length != selectionStart){
            if(e.data && /\D/g.test(e.data)){
                setValue(numbersValue);
            }
            return
        }

        if(["7", "8", "9"].indexOf(numbersValue[0]) > -1 ){
            if(numbersValue[0] == '9'){
                console.log('Test')
                numbersValue = '7' + numbersValue;
            }

            let firstSymbols = (numbersValue[0] == '8') ? '8' : '+7'
            formattedValue = firstSymbols + ' ';

            if(numbersValue.length > 1){
                formattedValue += '(' + numbersValue.substring(1, 4)
            }
            if(numbersValue.length >= 5){
                formattedValue += ') ' + numbersValue.substring(4, 7) 
            }
            if(numbersValue.length >= 8){
                formattedValue += '-' + numbersValue.substring(7, 9)
            }
            if(numbersValue.length >= 10){
                formattedValue += '-' + numbersValue.substring(9, 11)
            }

        }else{
            formattedValue = '+' + numbersValue.substring(0, 16)
        }

        setValue(formattedValue);
    }

    const onPhoneKeyDown = (e) => {
        let input = e.target
        if(e.keyCode == 8 && getNumbersValue(input.value).length == 1){
            setValue('')
        }
    }
    
    const onPhonePaste = (e) => {
        const pasted = e.clipboardData;
        const numbersValue = getNumbersValue(e.target.value)
        
        if(pasted){
            let pastedText = pasted.getData("Text")

            if(/\D/g.test(pastedText)){
                setValue(numbersValue)
            }
        }
    }

    return (
        <>
            <Input {...props} value={value} type="tell" onInput={onPhoneInput} onKeyDown={onPhoneKeyDown} onPaste={onPhonePaste}/>
        </>
    )
}

export default PhoneInput;