import baseUrl from "../../infrastructure/url"
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { SaveServiceAction } from "../../redux/actions/serviceAction";
import { GetCategoriesAction } from "../../redux/actions/serviceCategoryActions";
import { Button, Form, Input, InputNumber, TimePicker, Select, Upload, message } from "antd";
import { BiUpload } from "react-icons/bi"
import Modal from "../common/modal";

const ServiceForm = (props) => {
    const title = props.service?.id > 0 ? 'Редактирование сервиса' : 'Создание сервиса';

    const [isLoading, setLoading] = useState();
    const [formFields, setFormFields] = useState();
    const [fileList, setFileList] = useState([]);

    const accessToken = useSelector(state => state.account.accessToken.value);
    const categories = useSelector(state => state.serviceCategories.categories)

    const categorySelectOptions = categories.map(category => ({ value: category.id, label: category.name }))

    const dispatch = useDispatch();

    const saveService = (formData) => {
        const data = {
            ...formData,
            id: props.service?.id,
            duration: formData.duration.format('hh:mm:ss'),
            previewKey: formData.preview?.file.response.key ?? props.service.previewKey
        };

        const before = () => setLoading(true);
        const after = () => {
            setLoading(false);
            props.onDone();
        };

        dispatch(SaveServiceAction(data, before, after));
    }

    const beforePreviewUpload = (file) => {
        const isImageFile = file.type.includes('image/');

        if (!isImageFile) {
            message.error(`Файл '${file.name}' не является изображением `);
        }
        
        return isImageFile || Upload.LIST_IGNORE;
    };

    const previewRules = !props.service.previewKey 
        ? [{ required: true, message: 'Загрузите изображение' }]
        : [];

    useEffect(() => {
        setFormFields([
            {
                name: 'id',
                value: props.service?.id
            },
            { 
                name: 'name',
                value: props.service?.name
            }, 
            { 
                name: 'duration',
                value: props.service?.duration
            }, 
            { 
                name: 'cost',
                value: props.service?.cost
            }, 
            { 
                name: 'discountCost',
                value: props.service?.discountCost
            }, 
            { 
                name: 'categoryId',
                value: props.service?.categoryId
            },
            { 
                name: 'description',
                value: props.service?.description
            },
        ]);

        if (props.service.previewUrl)
        {
            const previewItem = {
                name: props.service.previewKey,
                url: props.service.previewUrl
            };

            setFileList([ previewItem ]);
        } 
        else 
        {
            setFileList([]);
        }        

    }, [props.service])

    useEffect(() => {
        if(!categories.length){
            dispatch(GetCategoriesAction(() => {}, () => {}));
        }
    }, [])

    return (<Modal isOpen={props.isOpen} title={title} 
        onCancel={props.onCancel}
        confirmButtonProps={{form: 'serviceForm', htmlType:'submit'}}
        isLoading={isLoading}>
        {{
            body: (
                <Form 
                    id="serviceForm" 
                    layout="vertical" 
                    style={{marginBottom: '30px'}}
                    fields={formFields}
                    onFinish={saveService} 
                >
                    <Form.Item hidden={true} name="id"/>

                    <Form.Item
                        label="Название"
                        name="name"
                        rules={[{required: true, message: 'Введите название'}]}
                    >
                        <Input/>
                    </Form.Item>
                    
                    <Form.Item
                        label="Длительность"
                        name="duration"
                        rules={[{required: true, message: 'Укажите длительность'}]}
                    >
                        <TimePicker format="HH:mm" style={{ width: '100%' }}/>
                    </Form.Item>

                    <Form.Item
                        label="Изображение"
                        name="preview"
                        rules={previewRules}
                    >
                        <Upload 
                            action={baseUrl + '/api/file'}
                            maxCount={1}
                            listType='picture'       
                            headers={{method: 'POST', authorization: `Bearer ${accessToken}`}}
                            beforeUpload={beforePreviewUpload}
                            onChange={(parameters) => setFileList(parameters.fileList)}
                            fileList={fileList}>
                            <Button style={{width: '400px'}} icon={<BiUpload/>}>Загрузить изображени</Button>
                        </Upload>
                    </Form.Item>

                    <Form.Item
                        label="Цена"
                        name="cost"
                        rules={[{required: true, message: 'Введите текущую цену'}]}
                    >
                        <InputNumber style={{ width: '100%' }}/>
                    </Form.Item>

                    <Form.Item
                        label="Скидочная цена"
                        name="discountCost"
                    >
                        <InputNumber style={{ width: '100%' }}/>
                    </Form.Item>

                    <Form.Item
                        label="Категория"
                        name="categoryId"
                        rules={[{required: true, message: 'Выберите категорию'}]}
                    >
                        <Select options={categorySelectOptions}/>
                    </Form.Item>

                    <Form.Item
                        label="Описание"
                        name="description"
                        rules={[{required: true, message: 'Введите описание'}]}
                    >
                        <Input.TextArea/>
                    </Form.Item>
                </Form>
            )
        }}
    </Modal>)
}

export default ServiceForm;
