import { ServiceTypes } from "../actionTypes";

const initialState = {
    services: []
}

const ServiceReducer = (state = initialState, action) => {
    switch(action.type)
    {
        case ServiceTypes.SET_SERVICES:
            return {
                ...state,
                services: action.payload
            };
        default: 
            return state;
    }
}

export default ServiceReducer;