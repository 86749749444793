import baseUrl from '../../infrastructure/url';
import Http from '../../infrastructure/http';
import { AccountTypes } from "../actionTypes";
import { LoginAction } from "../actions/accountActions";
import { MiddlewareErrorHandler } from "./middlewareExtensions";

const middleware = (store) => (next) => async (action) => {
    const state = store.getState();
    const http = new Http(state.account.accessToken.value);

    var errorHandler = new MiddlewareErrorHandler(store);

    switch(action.type)
    {
        case AccountTypes.AUTHENTICATE:
            action.before();
            errorHandler.execute(Authenticate, action.after);
            break;
        case AccountTypes.REFRESH: 
            TryRefresh(action.failAction)
            break;
    }
    
    return next(action);

    async function Authenticate()
    {
        const url = baseUrl + '/api/account/auth';
        const request = { 
            email: action.payload.email,
            password: action.payload.password
        };

        const response = await http.post(url, request);

        const loginAction = LoginAction(response.accessToken, response.refreshToken);
        store.dispatch(loginAction)
    }

    async function TryRefresh(failAction) 
    {
        try 
        {
            await Refresh();
        } 
        catch(error) 
        {
            failAction();
        }
    }

    async function Refresh()
    {
        const url = baseUrl + '/api/account/refresh';
        const request = { refreshToken: state.account.refreshToken };

        const response = await http.post(url, request);

        const loginAction = LoginAction(response.accessToken, response.refreshToken);
        store.dispatch(loginAction)
    }
}

export default middleware;