import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { SaveCategoryAction } from "../../redux/actions/serviceCategoryActions";
import { Form, Input } from "antd";
import Modal from "../common/modal";

const ServiceCategoryForm = (props) => {
    const dispatch = useDispatch();

    const title = props.category?.id > 0 ? 'Редактирование категорию' : 'Создание категорию';

    const [isLoading, setLoading] = useState();
    const [formFields, setFormFields] = useState();

    const saveCategory = (formData) => {
        const before = () => setLoading(true);
        const after = () => {
            setLoading(false);
            props.onDone();
        };

        dispatch(SaveCategoryAction(formData, before, after));
    }

    useEffect(() => {
        setFormFields([
            {
                name: 'id',
                value: props.category?.id
            },
            { 
                name: 'name',
                value: props.category?.name
            }
        ]);
    }, [props.category])

    return (<Modal isOpen={props.isOpen} title={title} 
        onCancel={props.onCancel}
        confirmButtonProps={{form: 'serviceCategoryForm', htmlType:'submit'}}
        isLoading={isLoading}>
        {{
            body: (
                <Form 
                    id="serviceCategoryForm" 
                    layout="vertical" 
                    onFinish={saveCategory} 
                    style={{marginBottom: '30px'}}
                    fields={formFields}
                >
                    <Form.Item hidden={true} name="id"/>

                    <Form.Item
                        label="Название"
                        name="name"
                        rules={[{required: true, message: 'Введите имя категории'}]}
                    >
                        <Input/>
                    </Form.Item>
                </Form>
            )
        }}    
    </Modal>)
}

export default ServiceCategoryForm;
