import whiteLogo from "../../assets/img/logo_white.svg";
import whitePhone from "../../assets/img/phone_white.svg";
import './footer.scss'

const Footer = () => {
    return (
        <section className="footer"> 
            <div className="container footer__container"> 
                <div className="logo footer__logo"> 
                    <img className="img" src={whiteLogo} alt="logo"/> 
                    <span className="logo__text"> 
                        Дух кочевника 
                    </span> 
                </div> 
        
                <span className="footer__rights"> 
                    {new Date().getFullYear()} © Все права защищены 
                </span> 
        
                <div className="contacts footer__contacts"> 
                    <a className="link contacts__phone phone" href="tel:79339996111"> 
                        <img className="phone__img contacts__phone__img" src={whitePhone} alt="phone-icon"/> 
                        <span className="phone__number"> 
                            +7 933 999 61-11
                        </span> 
                    </a> 
                </div> 
            </div> 
        </section> 
    )
}

export default Footer;