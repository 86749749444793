import { useState } from "react";
import { Input, Form, message} from "antd";

import OrderService from "../../services/orderService";

import Button from "../hero/button";
import PhoneInput from "../common/phoneInput";
import feedback from "../../assets/img/feedback_img.png";
import "./feedback.scss";

const Feedback = () => {
    const [messageApi, contextHolder] = message.useMessage();

    const [isLoading, setLoadingStatus] = useState();

    const sendHelpOrder = async (formData) => {
        setLoadingStatus(true);

        const customerData = {
            name: formData.name,
            phone: formData.phone,
            email: formData.email ?? ''
        };

        var orderService = new OrderService();

        try{
            setLoadingStatus(true);
            await orderService.orderHelp(customerData);
            
            messageApi.open({
                type: 'success',
                content: 'Заявка успешно отправлена, ожидайте!'
            })        
        } catch(e){
            messageApi.open({
                type: 'error',
                content: 'Что-то пошло не так, попробуйте позже.'
            })
        } finally {
            setLoadingStatus(false);
        }
    }

    return (
        <section className="feedback"> 
            {contextHolder}
            <div className="container feedback__container"> 
        
                <h2 className="title feedback__title"> 
                    Есть вопросы ? <br /> 
                    Закажите бесплатную консультацию 
                </h2> 
        
                <div className="form">
                    <Form
                        id="orderHelp" 
                        layout="vertical"
                        onFinish={sendHelpOrder}
                    >
                        <Form.Item 
                            label="Имя" 
                            name="name"
                            rules={[{required: true, message: 'Введите ваше имя'}]}>
                            <Input style={{borderRadius: '15px'}}/>
                        </Form.Item>

                        <Form.Item 
                            label="Телефон" 
                            name="phone"
                            rules={[{required: true, min: 17, message: 'Введите ваш номер телефона'}]}>
                            <PhoneInput style={{borderRadius: '15px'}}/>
                        </Form.Item>

                        <Form.Item 
                            label="Почта" 
                            name="email">
                            <Input style={{borderRadius: '15px'}}/>
                        </Form.Item>

                        <Form.Item>
                            <Button loading={isLoading} type="danger" size="medium">
                                Заказать
                            </Button>
                        </Form.Item>
                    </Form>
                </div>
            </div> 
            <img src={feedback} className="feedback__img" alt=""/> 
        </section> 
    );
}

export default Feedback;