import { Typography } from "antd";
import { NavLink } from "react-router-dom";
import { BiCategory, BiUser, BiLogOutCircle} from "react-icons/bi";
import { BsTags } from "react-icons/bs";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { LogoutAction } from "../../redux/actions/accountActions";
import "./sidebar.scss";

const { Title } = Typography;

const Sidebar = () => {

    const navigate = useNavigate();
    const dispatch = useDispatch();

    const logout = () => {
        dispatch(LogoutAction());
        navigate('/account/signin');
    }

    return (<div className="sidebar">
        <div className="sidebar-header">
            <NavLink to="/">
                <Title level={3} style={{color: '#fff'}}>Nomad spirit</Title>
            </NavLink>
        </div>

        <div className="sidebar-body">
            <NavLink to="/account/serviceCategories" className={({isActive}) => 'sidebar-nav-link ' + (isActive ? 'sidebar-selected-link' : '')}>
                <BsTags className="sidebar-nav-link-text" size="20px"/>
                <span>Категории</span>
            </NavLink>
            <NavLink to="/account/services" className={({isActive}) => 'sidebar-nav-link ' + (isActive ? 'sidebar-selected-link' : '')}>
                <BiCategory className="sidebar-nav-link-text" size="20px"/>
                <span>Сервисы</span>
            </NavLink>
            {/* <NavLink to="/account/customers" className={({isActive}) => 'sidebar-nav-link ' + (isActive ? 'sidebar-selected-link' : '')}>
                <BiUser className="sidebar-nav-link-text" size="20px"/>
                <span>Клиенты</span>
            </NavLink> */}
        </div>

        <div className="sidebar-footer">
            <button onClick={logout} className="sidebar-nav-link logout-button">
                <BiLogOutCircle className="sidebar-nav-link-text" size="16px"/>
                <span>Выйти</span>
            </button>
        </div>
    </div>);
}

export default Sidebar;