import vk from "../../assets/img/vk.svg";
import telegram from "../../assets/img/telegram.svg";
import instagram from "../../assets/img/instagram.svg";
import "./aboutUs.scss";

const AboutUs = () => {
    return (
        <section id="aboutus" className="aboutus"> 
            <div className="container aboutus__container"> 
                <div className="aboutus__content"> 
                    <h2 className="title aboutus__title"> 
                        О нас 
                    </h2> 
        
                    <div className="description"> 
                        Дух кочевника — это дух приключений, который живёт в каждом человеке. Это путешествия от ПВД с гидами-проводниками до авторских туров от тревел-экспертов и частных независимых гидов. Наши туры — это, те самые спонтанные и яркие воспоминания жизни. <br /> <br /> 
                        Мы за путешествия, которые больше похожи на встречу друзей. И мы за путешествия после которых вы становитесь друзьями. Один раз в году иди туда, где никогда раньше не был. После того, как сходишь, иди ещё раз! 
                    </div> 
        
                    <u className="links aboutus__links"> 
                        <li className="links__item"> 
                            <a className="link" href="https://vk.com/duh_nomad"> 
                                <img src={vk} alt="vk.com"/> 
                            </a> 
                        </li> 
        
                        <li className="links__item"> 
                            <a className="link" href="https://www.instagram.com/duh_nomad/"> 
                                <img src={instagram} alt="instagram"/> 
                            </a> 
                        </li> 
        
                        <li className="links__item"> 
                            <a className="link" href="https://t.me/duh_nomad"> 
                                <img src={telegram} alt="telegram"/> 
                            </a> 
                        </li> 
                    </u> 
                </div> 
        
                <div className="aboutus__video"> 
                    <iframe width="560" height="315" src="https://www.youtube.com/embed/c0UsMqCdiq8" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen></iframe>
                </div> 
            </div> 
        </section> 
    );
}

export default AboutUs;