import { AddError } from "../actions/errorActions";

export class MiddlewareErrorHandler {
    constructor(store)
    {
        this.store = store;
        this.execute = this.execute.bind(this);
    }

    async execute(action, finallyAction)
    {
        try {
            await action();
        } catch (error) {
            this.store.dispatch(AddError(error));
        } finally {
            finallyAction();
        }        
    } 
}