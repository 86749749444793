import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { Row, Spin, Select } from "antd";

import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Pagination } from "swiper";
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';

import { GetServicesAction } from "../../redux/actions/serviceAction";
import { GetCategoriesAction } from "../../redux/actions/serviceCategoryActions";

import Button from "./button";
import OrderModalForm from "./orderModalForm";
import "./catalog.scss";

const Catalog = () => { 
    const dispatch = useDispatch();

    const [isServicesLoading, setServiceLoadingStatus] = useState();
    const [isCategoriesLoading, setCategoriesLoadingStatus] = useState();

    const allCategory = { id: -1, name: 'Все' }; 
    const [selectedCategory, setSelectedCategory] = useState(allCategory);
    const [selectedService, setSelectedService] = useState();
    const [isOrderFormOpen, setOrderFormOpenStatus] = useState();

    const categories = useSelector((state) => {
        return [
            allCategory,
            ...state.serviceCategories.categories
        ]
    });

    const services = useSelector(state => state.services.services);
    const filterdServices = selectedCategory.id == allCategory.id 
        ? services
        : services.filter(service => service.categoryId == selectedCategory.id);

    const openOrderForm = (service) => {
        setSelectedService(service);
        setOrderFormOpenStatus(true);
    }

    const closeOrderForm = (afterClose) => {
        setOrderFormOpenStatus(false);
        afterClose();
    }

    const formatDuration = (duration) => {
        let durationParts = [];

        if(duration.hours() > 0){
            durationParts.push(duration.format('h [ч.]'))
        }

        if(duration.minutes() > 0){
            durationParts.push(duration.format('m [мин.]'))
        }

        return durationParts.join(' ');
    }

    const getServiceCard = (service) => {
        return (
            <SwiperSlide key={service.id}>
                <div className="catalog-item"> 
                    <div className="catalog-item__img" style={{background: `url(${service.previewUrl})`}}></div> 
                    <div className="catalog-item__content"> 
                        <div className="catalog-item__title">
                            { service.name }    
                        </div> 
                        <div className="catalog-item__description">
                            { service.description}    
                        </div> 
                        <div className="catalog-item__properties"> 
                            <div className="catalog-item__property"> 
                                <div className="catalog-item__property__title"> 
                                    Продолжительность: 
                                </div> 
                                <div className="catalog-item__property__value duration">
                                    { formatDuration(service.duration) }    
                                </div> 
                            </div> 
                        </div> 
                    </div> 
                
                    <div className="catalog-item__footer"> 
                        <div className="catalog-item__costs"> 
                            <div className="catalog-item__costs__current">
                                { (service.discountCost ?? service.cost) + ' р.'}    
                            </div> 
                            <div className="catalog-item__costs__old">
                                { service.discountCost ? service.cost + ' р.' : '' }    
                            </div> 
                        </div> 
                
                        <div className="catalog-item__footer__order"> 
                            <Button type="danger" size="medium" onClick={() => openOrderForm(service)}>
                                Заказать 
                            </Button>
                        </div> 
                    </div> 
                </div>
            </SwiperSlide>
        )
    }

    useEffect(() => {
        dispatch(GetCategoriesAction(() => setCategoriesLoadingStatus(true), () => setCategoriesLoadingStatus(false)));
        dispatch(GetServicesAction(() => setServiceLoadingStatus(true), () => setServiceLoadingStatus(false)));
    }, [])

    return (
        <section id="catalog" className="catalog"> 

            <OrderModalForm 
                isOpen={isOrderFormOpen}
                service={selectedService}
                onCancel={closeOrderForm}/>

            <div className="container catalog__container"> 
                <h2 className="title"> 
                    Каталог 
                </h2> 
                <div className="catalog__categories"> 
                    {
                        categories.map(category => {
                            return (
                                <Button
                                    key={category.id} 
                                    type="danger"
                                    outline={selectedCategory.id == category.id ? false : true}
                                    size="medium" 
                                    onClick={() => setSelectedCategory(category)}>
                                    {category.name} 
                                </Button>
                            ) 
                        })
                    }
                </div> 
                
                <div className="catalog__categories-select">
                    <Select 
                        style={{ width: '100%' }}
                        value={selectedCategory.id}
                        options={categories.map(x => ({ label: x.name, value: x.id}))}
                        onSelect={(value) => setSelectedCategory(categories.find(x => x.id == value))}>
                    </Select>
                </div>
                    
                {
                    isServicesLoading || isCategoriesLoading
                        ? <Row justify="center" style={{padding: '30px'}}><Spin/></Row>
                        : <div className="catalog__services"> 
                            {
                                !filterdServices.length 
                                    ? <div style={{textAlign: 'center'}}> Нет соответствующих элементов </div>
                                    : <>
                                        <Swiper
                                            className="catalog__services__swiper"
                                            modules={[Navigation, Pagination]}
                                            navigation={{ 
                                                nextEl: '.swiper-button-next',
                                                prevEl: '.swiper-button-prev'}
                                            }
                                            pagination={{ el: '.swiper-pagination', clickable: true }}
                                            slidesPerView={3}
                                            breakpoints={{
                                                300: {
                                                    slidesPerView: 1
                                                },
                                                460: {
                                                    slidesPerView: 1.1
                                                },
                                                860: {
                                                    slidesPerView: 2
                                                },
                                                1200: {
                                                    slidesPerView: 3
                                                }
                                            }}
                                        >
                                            { filterdServices.map(getServiceCard) }
                                        </Swiper>
                                        <div className="swiper-button-prev"></div> 
                                        <div className="swiper-button-next"></div> 
                                        <div className="swiper-pagination"></div> 
                                    </>
                            }
                        </div>  
                }
            </div>
        </section> 
    );
};


export default Catalog;