import { useState, useEffect } from 'react';
import { Layout, Typography, Spin, Row } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { isAuthorizedSelector } from '../redux/selectors/accountSelectors';
import { useNavigate, Outlet, NavLink } from 'react-router-dom';
import Sidebar from "../components/common/sidebar";
import { RefreshAction } from '../redux/actions/accountActions';
import "./account.scss";
import ErrorNotificator from '../components/common/errorNotificator';

const { Content } = Layout;
const { Title } = Typography;

const Account = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const isAuthorized = useSelector(isAuthorizedSelector);
    const refreshToken = useSelector(state => state.account.refreshToken);

    const pushToSingInPage = () => {
        navigate('/account/signin');
    }

    useEffect(() => {
        if (!isAuthorized) {
            if (refreshToken) {
                dispatch(RefreshAction(pushToSingInPage));
            } else {
                pushToSingInPage();
            }
        }
    });

    // HACK: show empty space if not authorized.
    if(!isAuthorized){
        return <Row justify="center" align="middle" style={{ height: '100vh'}}>
            <Spin/>
        </Row>
    }

    return (
        <div className="account-wrapper">
            <Sidebar/>
            <ErrorNotificator/>
            <Content style={{ padding: '25px'}}> 
                <Outlet/>
            </Content>
        </div>
    );
}

export default Account;