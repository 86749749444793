import { Spin } from "antd";
import { LoadingOutlined } from '@ant-design/icons';

import "./button.scss";

const HeroButton = (props) => {

    let className = `hero-button hero-button__${props.size} hero-button__${props.type} ${props.className}`;
    
    if(props.outline){
        className += " hero-button__outline"
    }

    const spinIcon = <LoadingOutlined style={{ color: "#fff", fontSize: 14 }} spin />

    return (
        props.href 
            ? <a className={className} href={props.href} onClick={props.onClick}>{props.children}</a>
            : <button 
                className={className}
                onClick={props.onClick}
                type={props.htmlType}
                form={props.form}
                disabled={props.loading ? true : false}
                >
                {
                    props.loading ? <Spin style={{marginRight: '8px'}} indicator={spinIcon}/> : <></>
                }

                {props.children}
            </button>
    )
}

export default HeroButton;