import { ErrorTypes } from "../actionTypes"

export const AddError = (error) => {
    return {
        type: ErrorTypes.ADD,
        payload: error
    };
}

export const RemoveError = (error) => {
    return {
        type: ErrorTypes.REMOVE,
        payload: error
    }
}

export const RemoveErrors = (error) => {
    return {
        type: ErrorTypes.REMOVE_ERRORS,
        payload: error
    }
}