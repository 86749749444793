import { useState } from "react";
import { useDispatch } from "react-redux";
import ConfirmationPopup from "../common/confirmPopup";
import { DeleteServiceAction } from "../../redux/actions/serviceAction";

const ServiceDeleteDialog = (props) => {
    const [isLoading, setIsLoading] = useState();
    const dispatch = useDispatch();

    const deleteService = () => {
        const before = () => setIsLoading(true);
        const after = () => {
            setIsLoading(false);
            props.onDone();
        };

        dispatch(DeleteServiceAction(props.service.id, before, after));
    }

    return <ConfirmationPopup
        isOpen={props.isOpen}
        isLoading={isLoading}
        title="Вы уверены, что хотите удалить услугу?"
        text="После этого данные не могут быть восстановлены!"
        onCancel={props.onCancel}
        onConfirm={deleteService}
    />
}

export default ServiceDeleteDialog;

