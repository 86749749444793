import store from './redux/store'
import { Provider as ReduxProvider} from 'react-redux'
import { ConfigProvider as AntConfigProvider, theme } from "antd";
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Hero from './pages/hero';
import Account from './pages/account';
import SignIn from './pages/signIn';
import Customers from './pages/customers';
import ServiceCategories from './pages/serviceCategories';
import Services from './pages/services';

const App = () => {
    return (
        <AntConfigProvider>
            <ReduxProvider store={store}>
                <Router>
                    <Routes>
                        <Route index key="hero" element={<Hero/>}/>
                        <Route key="account" path="/account" element={<Account/>}>
                            <Route key="services" path="services"  element={<Services/>}/>
                            <Route key="customers" path="customers" element={<Customers/>}/>
                            <Route key="serviceCategories" path="serviceCategories" element={<ServiceCategories/>}/>
                        </Route>
                        <Route key="signin" path="/account/signin" element={<SignIn/>}/>
                    </Routes>
                </Router>
            </ReduxProvider>
        </AntConfigProvider>
    );
}

export default App;