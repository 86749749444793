import baseUrl from '../../infrastructure/url';
import Http from '../../infrastructure/http';
import { ServiceCategoryTypes } from "../actionTypes";
import { SetCategoriesAction } from "../actions/serviceCategoryActions";
import { MiddlewareErrorHandler } from "./middlewareExtensions";

const categoryUrl = baseUrl + '/api/serviceType';

const serviceCategoryMiddleware = (store) => (next) => async (action) => {
    const state = store.getState();
    const http = new Http(state.account.accessToken.value);

    var errorHandler = new MiddlewareErrorHandler(store);

    switch(action.type)
    {
        case ServiceCategoryTypes.GET: 
            action.before();
            errorHandler.execute(GetCategories, action.after);
            break;
        case ServiceCategoryTypes.SAVE: 
            action.before();
            errorHandler.execute(SaveCategory, action.after);
            break;
        case ServiceCategoryTypes.DELETE:
            action.before();
            errorHandler.execute(DeleteCategory, action.after);
            break; 
    }
    
    return next(action);

    async function GetCategories()
    {
        const response = await http.get(categoryUrl);

        const categories = response.types;
        categories.forEach((category, index) => {
            category.key = index;
            category.order = index + 1;
        });

        store.dispatch(SetCategoriesAction(categories));
    }

    async function SaveCategory()
    {
        await http.post(categoryUrl, action.payload);
    }

    async function DeleteCategory()
    {
        await http.delete(`${categoryUrl}?id=${action.payload}`);
    }
}

export default serviceCategoryMiddleware;