import { ServiceCategoryTypes } from "../actionTypes"

export const GetCategoriesAction = (before, after) => {
    return {
        type: ServiceCategoryTypes.GET,
        before,
        after
    };
}

export const SaveCategoryAction = (category, before, after) => {
    return {
        type: ServiceCategoryTypes.SAVE,
        payload: category,
        before, 
        after
    };
}

export const DeleteCategoryAction = (categoryId, before, after) => {
    return {
        type: ServiceCategoryTypes.DELETE,
        payload: categoryId,
        before, 
        after
    };
}

export const SetCategoriesAction = (payload) => {
    return {
        type: ServiceCategoryTypes.SET_CATEGORIES,
        payload
    };
}