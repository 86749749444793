import HttpError from "./httpError";

export default class Http {

    constructor(accessToken){
        this.accessToken = accessToken;

        this.sendRequest = this.sendRequest.bind(this);
        this.getHeader = this.getHeaders.bind(this);
        this.parseResponse = this.parseResponse.bind(this);
    }
    
    get(url, data){
        return this.sendRequest(url, data, 'GET');
    }

    post(url, data){
        return this.sendRequest(url, data, 'POST');
    }

    put(url, data){
        return this.sendRequest(url, data, 'PUT');
    }

    delete(url, data){
        return this.sendRequest(url, data, 'DELETE');
    }

    sendRequest(url, data, method){
        return fetch(url, {
            method: method,
            headers: this.getHeaders(),
            body: JSON.stringify(data)
        }).then(this.parseResponse);
    }

    getHeaders(){
        return {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${this.accessToken}`,
        };
    }

    parseResponse(response){
        if(!response.ok){
            return response.json().then(error => {
                throw new HttpError(error);
            });
        }

        const contentType = response.headers.get("content-type");
        if (contentType && contentType.indexOf("application/json") !== -1)
            return response.json();
    }
}