import { ErrorTypes } from '../actionTypes';

const initialState = {
    errors: []
};

const ErrorReducer = (state = initialState, action) => {

    switch(action.type){
        case ErrorTypes.ADD: {
            let error = { 
                title: action.payload.title,
                description: action.payload.details
            };

            return {
                errors: [ ...state.errors, error]
            }
        }
        case ErrorTypes.REMOVE: {
            return {
                errors: state.errors.filter(error => error != action.payload)
            }
        }
        case ErrorTypes.REMOVE_ERRORS: {
            return {
                errors: state.errors.filter(error => action.payload.indexOf(error) == -1)
            }
        }
        default:
            return state;
    }
};

export default ErrorReducer;