import Button from "../hero/button";

import discount from "../../assets/img/discount_img.png";
import "./discount.scss"

const Discount = () => {
    return (
        <section id="bonus" className="discount"> 
            <div className="container discount__container"> 
        
                <div className="discount__content"> 
                    {/* <h1 className="title title__large "> 
                        исполним ваши желания
                    </h1> 
                    <div className="description description__large"> 
                        Пройдите тест и получите персональную программу подобранную специально под вас!
                    </div>  */}

                    <h1 className="title title__large "> 
                        Планируете отдых на снегоходах в Красноярске ?
                    </h1> 
                    <div className="description description__large"> 
                        Пройдите тест и получите не менее 3 вариантов отдыха на снегоходах около города
                    </div> 


                    <Button href="https://nomadspirit.ru/" type="primary" size="large">
                        Пройти тест
                    </Button>
                </div> 
        
                <img className="discount__img img" src={discount} alt="discount-img"/> 
            </div> 
        </section> 
    )
}

export default Discount;