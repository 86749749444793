import { createStore, applyMiddleware, compose } from 'redux';
import thunk from 'redux-thunk';
import rootReducer from './reducers';
import accountMiddleware from './middlewares/accountMiddleware';
import serviceCategoryMiddleware from './middlewares/serviceCategoryMiddleware';
import serviceMiddleware from "./middlewares/serviceMiddleware"

const initialState = { };

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

export default createStore(
    rootReducer, 
    initialState, 
    composeEnhancers(applyMiddleware(thunk, accountMiddleware, serviceCategoryMiddleware, serviceMiddleware)),
);