export const isAuthorizedSelector = (state) => {
    const token = state.account.accessToken;

    if(!token){ 
        return false;
    }

   return new Date() < token.exp;
}

export const userNameSelector = (state) => {    
    return state.account.accessToken.UserName;
}