export const AccountTypes = {
    AUTHENTICATE: 'account/authenticate',
    REFRESH: 'account/refresh',

    LOGOUT: 'account/logout',
    LOGIN: 'account/login'
}

export const ErrorTypes = {
    ADD: 'error/add',
    REMOVE: 'error/remove',
    REMOVE_ERRORS: 'errors/remove',
}

export const ServiceCategoryTypes = {
    GET: 'serviceCategory/get',
    SET_CATEGORIES: 'serviceCategory/set',
    SAVE: 'serviceCategory/save',
    DELETE: 'serviceCategory/delete',
}

export const ServiceTypes = {
    GET: 'service/get',
    SET_SERVICES: 'service/set',
    SAVE: 'service/save',
    DELETE: 'service/delete',
}

export const CustomerTypes = {
    GET: 'customer/get',
}

export const OrderTypes = {
    SERVICE_ORDER: 'order/service',
    HELP_ORDER: 'order/help'
}