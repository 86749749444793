import { Button, Typography } from 'antd';
import './modal.scss';

const { Title } = Typography;

const afterModalClose = () => {
    // HACK: setting <html> element style to enable scrolling after modal close.
    document.documentElement.style.overflowY = '';
}

const Modal = (props) => {
    const tryCloseDialog = () => {
        if(!props.isLoading){
            props.onCancel(afterModalClose);
        }
    }

    // HACK: setting <html> element style to disable scrolling.
    if (props.isOpen){
        document.documentElement.style.overflowY = 'hidden';
    }

    let visibilityState = props.isOpen ? 'visible' : '';

    return (
        <div className={`dialog-overlay ${visibilityState}`} onClick={tryCloseDialog}>
            <div className={`dialog ${visibilityState}`} style={{ width: props.width }} onClick={(e) => e.stopPropagation()}>
                <div className="dialog-header">
                    <Title level={4}>{ props.title }</Title>
                </div>
                <div className="dialog-content">
                    { props.children.body }
                </div>
                
                <div className="dialog-footer">
                    {
                        props.children.footer 
                            ? props.children.footer
                            : (
                                <>
                                    <Button 
                                        type="default" 
                                        style={{marginRight: '15px'}}
                                        onClick={tryCloseDialog}> 
                                        { props.cancelButtonProps?.title ?? 'Отменить'}
                                    </Button>
                                    <Button 
                                        type="primary"
                                        htmlType={props.confirmButtonProps?.htmlType}
                                        form={props.confirmButtonProps?.form}
                                        loading={props.isLoading}
                                        onClick={props.onConfirm}>
                                        { props.confirmButtonProps?.title ?? 'Подтвердить'}
                                    </Button>
                                </>
                            )
                    }
                </div>
            </div>
        </div>
    );
}

export default Modal;