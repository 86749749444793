import { useState } from "react"; 

import { CloseOutlined } from "@ant-design/icons"

import Button from "../components/hero/button"

import Benifits from "../components/hero/benifits";
import Gallery from "../components/hero/gallery";
import Discount from "../components/hero/discount";
import AboutUs from "../components/hero/aboutUs";
import Reviews from "../components/hero/reviews";
import Feedback from "../components/hero/feedback";
import Catalog from "../components/hero/catalog";
import Footer from "../components/hero/footer";

import darkLogo from "../assets/img/logo_dark.svg";
import phoneDark from "../assets/img/phone_dark.svg";
import hero from "../assets/img/hero_img.png";
import "./hero.scss"

const Hero = () => {
    const [isMenuOpen, setMenuOpenStatus] = useState();

    const openMenu = () => {
        setMenuOpenStatus(true)
        document.documentElement.style.overflowY = 'hidden';
    }
    
    const closeMenu = () => {
        setMenuOpenStatus(false)
        document.documentElement.style.overflowY = '';
    }

    return (
        <div>
            <div className={'overlay ' + (isMenuOpen ? 'overlay__active' : '')} 
                onClick={closeMenu}/>

            <section className="hero" id="hero">
                <header className="header">
                    <div className={'container header__container'}>
                        <div className="burger__menu">
                            <div className="header__burger" onClick={openMenu}>
                                <div className="burger"></div>
                            </div>
                        
                            <div className="logo">
                                <img className="logo__img" src={darkLogo}/>
                                <span className="logo__text">Дух кочевника </span>
                            </div>
                        </div>

                        <div className={'header__mobile ' + (isMenuOpen ? 'header__mobile__active' : '')}>
                            <div className="logo">
                                <img className="logo__img" src={darkLogo}/>
                                <span className="logo__text">Дух кочевника </span>
                            </div>

                            <div className="navigation">
                                <ul className="menu">
                                    <li className="menu__item menu__item__active">
                                        <a href="#hero" className="link" onClick={closeMenu}> 
                                            Главная 
                                        </a> 
                                    </li>

                                    <li className="menu__item">
                                        <a href="#catalog" className="link" onClick={closeMenu}> 
                                            Каталог 
                                        </a> 
                                    </li>

                                    <li className="menu__item">
                                        <a href="#bonus" className="link" onClick={closeMenu}> 
                                            Бонусы 
                                        </a> 
                                    </li>

                                    
                                    <li className="menu__item">
                                        <a href="#aboutus" className="link" onClick={closeMenu}> 
                                            <nobr>О нас</nobr> 
                                        </a> 
                                    </li>

                                    <li className="menu__item">
                                        <a href="#reviews" className="link" onClick={closeMenu}> 
                                            <nobr>Отзывы</nobr> 
                                        </a> 
                                    </li>
                                </ul>

                                <div className="navigation__contacts"> 
                                    <a className="phone link" href="tel:79339996111"> 
                                        <img className="phone__img" src={phoneDark} alt="phone-icon"/> 
                                        <span className="phone__number"> 
                                            +7 933 999 61-11
                                        </span> 
                                    </a> 
                                </div> 
                            </div>
                        </div>
                    </div>
                </header>

                <div className="promo"> 
                    <div className="container promo__container"> 
                        <div className="promo__content"> 
                            <div className="title title__large"> 
                                Туры на снегоходах от 2000₽
                            </div> 
                            <div className="description description__large">  
                                Мы предоставляем большой выбор маршрутов по невысоким ценам. У нас и девушки и мужчины могут управлять снегоходами. 
                            </div> 
                            <Button 
                                className="promo__button"
                                type="primary" 
                                size="large" 
                                shape="round" 
                                href="#catalog">
                                Смотреть каталог 
                            </Button>

                        </div> 
                        <img className="promo__img img" src={hero} alt="promo-img"/> 
                    </div> 
                </div> 
            </section>

            <Benifits/>
            <Catalog/>
            {/* <Gallery/> */}
            <Discount/>
            <AboutUs/>
            <Reviews/>
            <Feedback/>
            <Footer/>
        </div>
    );
}

export default Hero;