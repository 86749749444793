import React, { useState, useEffect } from 'react';
import { Layout, Row, Card, Input, Button, Typography, Form, Spin, theme } from 'antd';
import { SiProgress } from 'react-icons/si';
import { AiOutlineUser } from 'react-icons/ai';
import { HiOutlineLockClosed } from 'react-icons/hi';
import { useDispatch, useSelector } from 'react-redux';
import { AuthenticateAction } from '../redux/actions/accountActions';
import { isAuthorizedSelector } from '../redux/selectors/accountSelectors'
import { useNavigate } from 'react-router-dom';
import ErrorNotificator from '../components/common/errorNotificator';

const { Title, Text } = Typography; 
const { useToken } = theme;

const SingIn = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { token } = useToken();

    const [isLoading, setIsLoading] = useState(false);
    const isAuthorized = useSelector(isAuthorizedSelector);

    useEffect(() => {
        if(isAuthorized){
            navigate('/account');
        }
    });

    // HACK: show empty space authorized.
    if(isAuthorized){
        return <Row justify="center" align="middle" style={{ height: '100vh'}}>
            <Spin/>
        </Row>
    }

    const handleFormFinish = (data) => {
        const before = () => setIsLoading(true);
        const after = () => setIsLoading(false);
        const action = AuthenticateAction(data.email, data.password, before, after);
        dispatch(action)   
    }

    return (
        <Layout>
            <ErrorNotificator/>
            <Row style={{ minHeight: '100vh' }} align="middle" justify="center">
                <Card style={{ minWidth: '450px', padding: '20px', borderRadius: '20px'}}>
                    <Row justify="center" style={{marginBottom: '10px'}}>
                        <SiProgress color="#0f8bfe" size="40px"/>
                    </Row>
                    
                    <Row justify="center">
                        <Title level={2} style={{marginBottom: 0}}>Добро пожаловать</Title>
                    </Row>

                    <Row justify="center">
                        <Text type="secondary" style={{fontSize: '14px', marginBottom: '30px'}}>
                            Введите свои учетные данные для доступа к аккаунту
                        </Text>
                    </Row>

                    <Form
                        layout="vertical"
                        id="signInForm"
                        style={{width: '100%'}}
                        onFinish={handleFormFinish}
                    >
                        <Form.Item
                            name="email"
                            rules={[{required: true, message: 'Пожалуйста, введите имя пользователя!'}]}
                            style={{marginBottom: '30px'}}
                        >
                            <Input
                                placeholder="Имя пользователя" 
                                prefix={<AiOutlineUser/>} 
                            />
                        </Form.Item>

                        <Form.Item
                            name="password"
                            rules={[{required: true, message: 'Пожалуйста, введите пароль!'}]}
                            style={{marginBottom: '30px'}}
                        >
                            <Input
                                placeholder="Пароль" 
                                type="password" 
                                prefix={<HiOutlineLockClosed/>} 
                            />
                        </Form.Item>
                    </Form>

                    <Button 
                        type="primary" 
                        htmlType="submit"
                        form="signInForm"
                        block
                        loading={isLoading}
                    >
                        Войти 
                    </Button>
                </Card>
            </Row>
        </Layout>
    );
}

export default SingIn;