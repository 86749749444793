import { useState } from "react";
import { useDispatch } from "react-redux";
import { DeleteCategoryAction } from "../../redux/actions/serviceCategoryActions";
import ConfirmationPopup from "../common/confirmPopup";

const ServiceCategoryDeleteDialog = (props) => {
    const [isLoading, setIsLoading] = useState();
    const dispatch = useDispatch();

    const deleteCategory = () => {
        const before = () => setIsLoading(true);
        const after = () => {
            setIsLoading(false);
            props.onDone();
        };

        dispatch(DeleteCategoryAction(props.category.id, before, after));
    }

    return <ConfirmationPopup
        isOpen={props.isOpen}
        isLoading={isLoading}
        title="Вы уверены, что хотите удалить категорию?"
        text="Все сервисы с данной категорией будут удалены!"
        onCancel={props.onCancel}
        onConfirm={deleteCategory}
    />
}

export default ServiceCategoryDeleteDialog;

