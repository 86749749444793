import { AccountTypes } from "../actionTypes"

export const AuthenticateAction = (email, password, before, after) => {
    return {
        type: AccountTypes.AUTHENTICATE,
        payload: { email, password },
        before,
        after
    };
};

export const RefreshAction = (failAction) => {
    return {
        type: AccountTypes.REFRESH,
        failAction: failAction
    }
}

export const LoginAction = (accessToken, refreshToken) => {
    return {
        type: AccountTypes.LOGIN,
        payload: {
            accessToken, 
            refreshToken
        }
    };
};

export const LogoutAction = () => {
    return {
        type: AccountTypes.LOGOUT
    };
};
