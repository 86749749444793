import { useEffect } from "react";
import './reviews.scss';

const Reviews = () => {
    useEffect(() => {
        const script = document.createElement('script');
        script.src = '//widget.flamp.ru/loader.js';
        script.async = 1;
        document.body.appendChild(script);

        return () => {
            document.body.removeChild(script);
        }
    }); 

    return (
        <section id="reviews" className="reviews"> 
            <div className="container reviews__container"> 
                <h2 className="title reviews__title"> 
                    Отзывы 
                </h2> 
        
                <div className="reviews__content"> 
                    <a className="flamp-widget" 
                        href="//krasnoyarsk.flamp.ru/firm/dukh_kochevnika_kompaniya_po_organizacii_aktivnykh_turov_i_prokata_snegokhodov-70000001057455463" 
                        data-flamp-widget-type="responsive-new" 
                        data-flamp-widget-id="70000001057455463" 
                        data-flamp-widget-width="100%" 
                        data-flamp-widget-count="1">
                        Отзывы о нас на Флампе
                    </a>
                </div> 
            </div> 
        </section> 
    )
}

export default Reviews;