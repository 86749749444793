import { combineReducers } from 'redux';
import AccountReducer from './accountReducer';
import ErrorReducer from './errorReducer';
import ServiceCategoryReducer from './serviceCategoryReducer';
import ServiceReducer from './serviceReducer';

const rootReducer = combineReducers({
    account: AccountReducer,
    errorsStorage: ErrorReducer,
    serviceCategories: ServiceCategoryReducer,
    services: ServiceReducer,
});

export default rootReducer;