import individual from "../../assets/img/individual.png";
import happy from "../../assets/img/happy.png";
import safetly from "../../assets/img/safetly.png";
import equipment from "../../assets/img/equipment.png";
import brief from "../../assets//img/brief.png";
import chill from "../../assets//img/chill.png";

import "./benifits.scss";

const Benifits = () => {
    return (
        <section className="benefits"> 
            <div className="container benefits__container"> 
                <h2 className="title benefits__title"> 
                    Почему выбирают нас ? 
                </h2> 
        
                <div className="benefits__content"> 
                    <div className="benefit"> 
                        <div className="benefit__icon benefit__icon__individual"> 
                            <img  src={individual} alt="benefit icon"/> 
                        </div> 
        
                        <div className="benefit__content"> 
                            <h3 className="title title__small"> 
                                Особый подход 
                            </h3> 
                            <div className="description"> 
                                Подстраиваем маршрут под клиента с учетом его пожеланий. 
                            </div> 
                        </div> 
                    </div> 

                    <div className="benefit"> 
                        <div className="benefit__icon benefit__icon__happy"> 
                            <img src={happy} alt="benefit icon"/> 
                        </div> 

                        <div className="benefit__content"> 
                            <h3 className="title title__small"> 
                                Не скучно 
                            </h3> 
                            <div className="description"> 
                                Большое разнообразие трасс и отличные эмоции. 
                            </div> 
                        </div> 
                    </div> 

                    <div className="benefit"> 
                        <div className="benefit__icon benefit__icon__safetly"> 
                            <img src={safetly} alt="benefit icon"/> 
                        </div> 

                        <div className="benefit__content"> 
                            <h3 className="title title__small"> 
                                Безопасно 
                            </h3> 
                            <div className="description"> 
                                Наши инструкторы заботятся о вашей безопасности.
                            </div> 
                        </div> 
                    </div> 
                </div> 

                <h2 className="title benefits__title"> 
                    Что входит в стоимость ? 
                </h2> 
        
                <div className="benefits__content"> 
                    <div className="benefit"> 
                        <div className="benefit__icon benefit__icon__equipment"> 
                            <img src={equipment} alt="benefit icon"/> 
                        </div> 
        
                        <div className="benefit__content"> 
                            <h3 className="title title__small"> 
                                Экипировка 
                            </h3> 
                            <div className="description"> 
                                Перчатки, шлемы, балаклавы - все для безопасности и комфорта. 
                            </div> 
                        </div> 
                    </div> 

                    <div className="benefit"> 
                        <div className="benefit__icon benefit__icon__brief"> 
                            <img src={brief} alt="benefit icon"/> 
                        </div> 

                        <div className="benefit__content"> 
                            <h3 className="title title__small"> 
                                Инструктаж
                            </h3> 
                            <div className="description"> 
                                Научим, покажем, объясним - если нужно, повторим)  
                            </div> 
                        </div> 
                    </div> 

                    <div className="benefit"> 
                        <div className="benefit__icon benefit__icon__chill"> 
                            <img src={chill} alt="benefit icon"/> 
                        </div> 

                        <div className="benefit__content"> 
                            <h3 className="title title__small"> 
                                Отдых после катания  
                            </h3> 
                            <div className="description"> 
                                Расслабиться, погреться, выпить чашку горячего чая.
                            </div> 
                        </div> 
                    </div> 
                </div> 
            </div> 
        </section> 
    )
}

export default Benifits;