import gallery_1 from "../../assets/img/gallery/gallery_img_1.JPG"
import gallery_2 from "../../assets/img/gallery/gallery_img_2.JPG"
import gallery_3 from "../../assets/img/gallery/gallery_img_3.JPG"
import gallery_4 from "../../assets/img/gallery/gallery_img_4.JPG"
import gallery_5 from "../../assets/img/gallery/gallery_img_5.JPG"
import gallery_6 from "../../assets/img/gallery/gallery_img_6.JPG"
import gallery_7 from "../../assets/img/gallery/gallery_img_7.JPG"
import gallery_8 from "../../assets/img/gallery/gallery_img_8.JPG"
import "./gallery.scss"

const Gallery = () => {
    return (
        <section id="gallery" className="gallery"> 
            <div className="container"> 
                <h2 className="title gallery__title"> 
                    Кадры с прошедших событий 
                </h2> 
        
                <div className="gallery-images"> 
                    <div className="gallery-image gallery-image__1" 
                        style={{background: `url(${gallery_1})`}}>
                    </div> 

                    <div className="gallery-image gallery-image__2" 
                        style={{background: `url(${gallery_2})`}}>
                    </div> 

                    <div className="gallery-image gallery-image__3" 
                        style={{background: `url(${gallery_3})`}}>
                    </div> 

                    <div className="gallery-image gallery-image__4" 
                        style={{background: `url(${gallery_4})`}}>
                    </div> 

                    <div className="gallery-image gallery-image__5" 
                        style={{background: `url(${gallery_5})`}}>
                    </div> 

                    <div className="gallery-image gallery-image__6" 
                        style={{background: `url(${gallery_6})`}}>
                    </div> 

                    <div className="gallery-image gallery-image__7" 
                        style={{background: `url(${gallery_7})`}}>
                    </div> 

                    <div className="gallery-image gallery-image__8" 
                        style={{background: `url(${gallery_8})`}}>
                    </div> 
                </div> 
            </div > 
        </section> 
    );
}

export default Gallery;