class HttpError extends Error {
    constructor(error){
        super('HTTP error');
        
        this.status = error.status;
        this.title = error.title;
        this.details = error.details;
    }
}

export default HttpError;